import React from 'react'
import ReactDOM from 'react-dom'
import { captureException } from '@thriveglobal/thrive-web-core'
import { ErrorBoundary } from '@thriveglobal/thrive-web-leafkit'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
    errorBoundary(err, info, props) {
        captureException(err, { info, props }, 'thrive-web-challenges')
        return (
            <ErrorBoundary>
                <></>
            </ErrorBoundary>
        )
    }
})

export const { bootstrap, mount, unmount } = lifecycles
export * from './shared'
